export enum StrategyEnum {
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND',
  PublicCompany = 'Mercado Público',
}

export type MetaData = {
  employeesRange: string;
  signatureType: string;
  strategy: StrategyEnum;
};

export type Group = {
  billingAuthorityId: string;
  billingMethod: string;
  cnpj: string;
  id: string;
  name: string;
  metadata?: MetaData;
};

export type AuthenticationFactorType = {
  name?: string | null;
  title: string;
  method: 'APP' | 'SMS';
  expiration?: number;
};

export type UserSessionData = Partial<{
  accessToken: string;
  mustChangePassword: boolean;
  userId: string;
  userName: string;
  email: string;
  multiLicense: boolean;
  group?: Group;
  authenticationFactorsRequired: boolean;
  authenticationFactors?: AuthenticationFactorType[];
}>;
